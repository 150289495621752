import { getCustomer } from '../notice/customer';
import { wrapError, wrapSuccess } from '../types/responses';
import { EFirebaseContext, EOrganization, ERef, EUser, exists } from '../types';

class CustomerService {
  public constructor(private context: EFirebaseContext) {}

  async getCustomerFromUserAndOrg(
    user: ERef<EUser>,
    organization: ERef<EOrganization>
  ) {
    const userSnap = await user.get();
    const organizationSnap = await organization.get();
    const customer = await getCustomer(
      this.context,
      userSnap,
      organizationSnap
    );

    if (!exists(customer)) {
      return wrapError(
        new Error(`customer not exists ${user.id}_${organization.id}`)
      );
    }
    return wrapSuccess(customer);
  }
}

export default CustomerService;
