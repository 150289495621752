import { getEnum } from './enums';
import {
  AdTypesData,
  AffidavitStatusTypeData,
  AutomatedAffidavitNotaries,
  AutomatedAffidavitNotaryEnum,
  AutomatedAffidavitNotaryType,
  AutomatedAffidavitParentOrganizationData,
  AutomatedAffidavitParentOrganizationItems,
  AutomatedAffidavitParentOrganizationItemType,
  AutomatedAffidavitSignerEnum,
  AutomatedAffidavitSigners,
  AutomatedAffidavitSignerType,
  BankAccountVerificationData,
  BillingStatusData,
  BillingTermTypeData,
  CountryData,
  CountryItems,
  CountryItemType,
  CurrencyData,
  CurrencyItems,
  CurrencyItemType,
  CustomAffidavitData,
  DayData,
  DayItems,
  DayItemType,
  DisplaySiteUploadStatusData,
  DisplaySiteUploadStatusItems,
  DisplaySiteUploadStatusItemType,
  DwollaCustomerStatusData,
  DwollaTransferStatusData,
  ExportFormatTypeData,
  ExportFormatTypeItems,
  ExportFormatTypeItemType,
  FilingTypeVisibilityData,
  FrequencyData,
  InviteStatusTypeData,
  InvoiceItemTypeData,
  InvoiceStatusData,
  LabelsData,
  LeeDisplayAdSizeData,
  LineItemTypeData,
  MailCenterData,
  MailCenterEnum,
  MailCenterType,
  MailStatusData,
  NotarizationPreconditionData,
  NotarizationPreconditionEnum,
  NotarizationPreconditionType,
  NoticeStatusData,
  NoticeStatusItems,
  NoticeStatusItemType,
  NoticeTypeData,
  NoticeTypeItems,
  NoticeTypeItemType,
  NotificationTypeData,
  NotificationTypeItems,
  NotificationTypeItemType,
  OccupationTypeData,
  OrganizationStatusData,
  OrganizationStatusItems,
  OrganizationStatusItemType,
  OrganizationTypeData,
  OrganizationTypeEnum,
  OrganizationTypeItems,
  PayoutStatusTypeData,
  RateTypeData,
  RateTypeItems,
  RateTypeItemType,
  RoleTypeData,
  RoleTypeItems,
  RoleTypeItemType,
  SpacerData,
  SpacerItems,
  SpacerItemType,
  StateData,
  StateItems,
  StateItemType,
  StripeMetadataTypeData,
  SubdomainStatusData,
  SubscriptionInvoiceItemStatusData,
  SyncStatusCategoryData,
  SyncStatusData,
  SyncStatusItems,
  SyncStatusItemType,
  TimezoneData,
  TimezoneItems,
  TimezoneItemType,
  TransactionTypeData,
  UserOccupationEnum,
  UserOccupationType,
  XMLReplacementTagData,
  XMLReplacementTagItems,
  XMLReplacementTagItemType
} from './shared';

import { CourtHouseData } from './CourtHouse';
import { EnumInputItem } from '../types/enums';
import {
  IntegrationTypeData,
  IntegrationTypeItemType,
  IntegrationTypeItems
} from './PublisherIntegrationType';

export const enumItemToSelectInput = ({ value, label }: EnumInputItem) => ({
  value: value.toString(),
  label
});

export const enumToSelectInput = (enumRef: { items: () => EnumInputItem[] }) =>
  enumRef.items().map(enumItemToSelectInput);

// BASIC ENUMS, not extended with additional data:
export const AdType = getEnum(AdTypesData);
export const AffidavitStatusType = getEnum(AffidavitStatusTypeData);
export const BankAccountVerification = getEnum(BankAccountVerificationData);
export const BillingStatusType = getEnum(BillingStatusData);
export const BillingTermType = getEnum(BillingTermTypeData);
export const CustomAffidavit = getEnum(CustomAffidavitData);
export const DwollaCustomerStatus = getEnum(DwollaCustomerStatusData);
export const DwollaTransferStatus = getEnum(DwollaTransferStatusData);
export const ELabels = getEnum(LabelsData);
export const Frequency = getEnum(FrequencyData);
export const InviteStatus = getEnum(InviteStatusTypeData);
export const InvoiceItemType = getEnum(InvoiceItemTypeData);
export const SubscriptionInvoiceItemStatus = getEnum(
  SubscriptionInvoiceItemStatusData
);
export const InvoiceStatus = getEnum(InvoiceStatusData);
export const LineItemType = getEnum(LineItemTypeData);
export const LeeDisplayAdSize = getEnum(LeeDisplayAdSizeData);
export const MailStatus = getEnum(MailStatusData);
export const FilingTypeVisibility = getEnum(FilingTypeVisibilityData);
export const PayoutStatusType = getEnum(PayoutStatusTypeData);
export const StripeMetadataType = getEnum(StripeMetadataTypeData);
export const SubdomainStatus = getEnum(SubdomainStatusData);
export const SyncStatusCategory = getEnum(SyncStatusCategoryData);
export const TransactionType = getEnum(TransactionTypeData);

// COMPLEX ENUMS, extended with additional data (requires type arguments to get proper types):
export const AutomatedAffidavitNotary = getEnum<
  AutomatedAffidavitNotaryType,
  AutomatedAffidavitNotaryEnum
>(AutomatedAffidavitNotaries);
export const AutomatedAffidavitParentOrganization = getEnum<
  AutomatedAffidavitParentOrganizationItems,
  AutomatedAffidavitParentOrganizationItemType
>(AutomatedAffidavitParentOrganizationData);
export const AutomatedAffidavitSigner = getEnum<
  AutomatedAffidavitSignerType,
  AutomatedAffidavitSignerEnum
>(AutomatedAffidavitSigners);
export const Country = getEnum<CountryItems, CountryItemType>(CountryData);
export const CurrencyType = getEnum<CurrencyItems, CurrencyItemType>(
  CurrencyData
);
export const Day = getEnum<DayItems, DayItemType>(DayData);
export const DisplaySiteUploadStatus = getEnum<
  DisplaySiteUploadStatusItems,
  DisplaySiteUploadStatusItemType
>(DisplaySiteUploadStatusData);
export const ExportFormatType = getEnum<
  ExportFormatTypeItems,
  ExportFormatTypeItemType
>(ExportFormatTypeData);
export const IntegrationType = getEnum<
  IntegrationTypeItems,
  IntegrationTypeItemType
>(IntegrationTypeData);
export const MailCenter = getEnum<MailCenterType, MailCenterEnum>(
  MailCenterData
);
export const NotarizationPrecondition = getEnum<
  NotarizationPreconditionType,
  NotarizationPreconditionEnum
>(NotarizationPreconditionData);
export const NoticeStatusType = getEnum<
  NoticeStatusItems,
  NoticeStatusItemType
>(NoticeStatusData);
export const NoticeType = getEnum<NoticeTypeItems, NoticeTypeItemType>(
  NoticeTypeData
);
export const NotificationType = getEnum<
  NotificationTypeItems,
  NotificationTypeItemType
>(NotificationTypeData);
export const OccupationType = getEnum<UserOccupationType, UserOccupationEnum>(
  OccupationTypeData
);
export const OrganizationStatus = getEnum<
  OrganizationStatusItems,
  OrganizationStatusItemType
>(OrganizationStatusData);
export const OrganizationType = getEnum<
  OrganizationTypeItems,
  OrganizationTypeEnum
>(OrganizationTypeData);
export const RateType = getEnum<RateTypeItems, RateTypeItemType>(RateTypeData);
export const RoleType = getEnum<RoleTypeItems, RoleTypeItemType>(RoleTypeData);
export const Spacer = getEnum<SpacerItems, SpacerItemType>(SpacerData);
export const State = getEnum<StateItems, StateItemType>(StateData);
export const SyncStatusType = getEnum<SyncStatusItems, SyncStatusItemType>(
  SyncStatusData
);
export const TimezoneType = getEnum<TimezoneItems, TimezoneItemType>(
  TimezoneData
);
export const XMLReplacementTagType = getEnum<
  XMLReplacementTagItems,
  XMLReplacementTagItemType
>(XMLReplacementTagData);

// Not exactly an Enum, but hey, we're open-minded around here and there's plenty of room
export const CourtHouse = CourtHouseData;

export { ConfirmationStatus } from './ConfirmationStatus';

export { ImplementationStatus } from './ImplementationStatus';

export { Product } from './Product';
